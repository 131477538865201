import React from "react";

import { useIntercom } from "$/hooks";
import { WEB_ASSETS_URL } from "$/configs/app.config";
import { ToolsIcon } from "$/components/Navigation";

export interface IProps {
  children: React.ReactNode;
  disableHeader?: boolean;
  disableFooter?: boolean;
}

export const PublicLayout: React.FC<IProps> = (props) => {
  useIntercom();

  return (
    <div className="mh-layout">
      <div className="flex flex-col w-full h-full">
        {!props.disableHeader && (
          <nav className="w-full flex justify-between px-4 md:px-12 border-0 border-solid border-b border-b-skin">
            <a href="https://morehappi.com">
              <img src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`} alt="logo" className="h-[64px]" />
            </a>
            <a href="/coaches" className="flex my-auto text-xl gap-x-4">
              <ToolsIcon />
              <p className="flex my-auto text-black no-underline">View our coaches</p>
            </a>
            <a href="/auth/login" className="flex my-auto text-xl gap-x-4">
              <img
                src={`${WEB_ASSETS_URL}/User_Icon_Smiley_round.svg`}
                alt="smiley"
                className="w-6"
              />
              <p className="flex my-auto text-black no-underline">Log in</p>
            </a>
          </nav>
        )}
        {props.children}
      </div>
    </div>
  );
};
