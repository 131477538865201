import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams, useSearchParams } from "react-router-dom";
import { useScrollTo } from "$/hooks";
import { HtmlMeta } from "$/components/HtmlMeta";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { CoachBio } from "$/components/CoachBio";
import { useUser } from "$/state/user";
import { IPageBaseProps } from "$/interfaces";
import {
  CoachEvent,
  useCoachAvailabilityQuery,
  useCreateBookingHoldTimerMutation,
  useGetCoachQuery,
} from "$/graphql/types.generated";
import { Mixpanel } from "$/tracking";
import { Scheduler } from "../../Sessions/Scheduler";
import { LabelList } from "$/components/CoachCard";
import { app } from "$/configs/app.config";
import { SchedulerProvider, useScheduler } from "../../Sessions/Scheduler/schedulerContext";
import { SchedulerTimes } from "../../Sessions/Scheduler/SchedulerTimes";
import { Button } from "$/components/Button";
import { ArrowLeft } from "lucide-react";
import { Modal } from "$/components/Modal";
import CoachTestimonials from "$/components/CoachCard/CoachTestimonials";
import { differenceInDays, parseISO } from "date-fns";
import { ProgressBar } from "../components/ProgressBar";
import { DateTime } from "luxon";

interface IProps extends IPageBaseProps {}

interface ScheduleProps {
  coachId: string;
  lastDate?: string;
}

export const Schedule = ({ coachId, lastDate }: ScheduleProps) => {
  const user = useUser();
  const navigate = useNavigate();
  const { setEvents, month } = useScheduler();
  const [searchParams] = useSearchParams();
  const [showTimeScheduler, setShowTimeScheduler] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data, isFetching } = useCoachAvailabilityQuery(
    {
      coachId,
      startsAt: month.startOf("month").toISO(),
      endsAt:
        lastDate && differenceInDays(parseISO(lastDate), parseISO(month.endOf("month").toISO())) < 0
          ? lastDate
          : month.endOf("month").toISO(),
    },
    { enabled: !!coachId },
  );

  useEffect(() => {
    if (!isFetching && data.coachAvailability?.length) {
      setEvents((data?.coachAvailability ?? []) as Array<CoachEvent>);
    }
  }, [isFetching]);

  const createBookingHoldTimer = useCreateBookingHoldTimerMutation();

  const submit = async () => {
    setLoading(true);
    try {
      const bookingHold = await createBookingHoldTimer.mutateAsync({
        input: {
          enquiryId: searchParams.get("eid"),
          coachId,
          teamMemberId: user.currentUser?.teamMember.id ?? null,
          sessionStartsAt: date?.plus({ hours: time }).toJSDate(),
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      {isFetching ? (
        <div className="w-full flex flex-col gap-4 items-center justify-center h-[465px] md:h-[646px]">
          <h1>Loading availability</h1>
          <LoadingSpinner className="!h-12 !w-12" />
        </div>
      ) : (
        <>
          {showTimeScheduler ? (
            <SchedulerTimes
              hideTimeScheduler={() => setShowTimeScheduler(false)}
              selectTime={(outDate, outTime) => {
                Mixpanel.track("Enquiry - selected time", { loggedIn: !!user.currentUser });
                setDate(outDate);
                setTime(outTime);
                setShowConfirmModal(true);
              }}
            />
          ) : (
            <Scheduler showTimeScheduler={() => setShowTimeScheduler(true)} />
          )}
        </>
      )}
      {showConfirmModal && (
        <Modal onClose={() => setShowConfirmModal(false)} size="lg">
          <div className="flex flex-col items-center">
            <img src="/images/Landing_Page.svg" alt="" className="h-[200px] w-[200px]" />
            <h2 className="text-2xl text-center mt-2">
              You've selected{" "}
              {date?.plus({ hours: time }).toLocaleString({
                weekday: "long",
                month: "long",
                day: "numeric",
                hour: "numeric",
                hourCycle: "h12",
              })}
            </h2>
            <>
              <p className="text-center text-lg">
                This date and time will be held for 15 minutes while you set up your programme.
              </p>
              <div className="flex gap-x-8 mt-4">
                <Button
                  primary
                  large
                  className="w-64"
                  onClick={async () => {
                    Mixpanel.track("Enquiry Time Slot held");
                    await submit();
                    navigate(`/enquiry/account?ctx=enquiry&eid=${searchParams.get("eid")}`);
                  }}
                >
                  {loading ? "Loading..." : "Confirm and View Programmes"}
                </Button>
              </div>
            </>
          </div>
        </Modal>
      )}
    </div>
  );
};

export const EnquiryBooking: React.FC<IProps> = () => {
  const user = useUser();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { coachHandle } = params;

  useScrollTo();

  const getCoachQuery = useGetCoachQuery({
    where: { handle: coachHandle },
  });

  useEffect(() => {
    if (!coachHandle || getCoachQuery.error) {
      return navigate(`/enquiry/results?eid=${searchParams.get("eid")}`);
    }

    if (getCoachQuery?.data?.getCoach) {
      Mixpanel.track("Coach Profile Viewed", {
        handle: coachHandle,
        loggedIn: !!user.currentUser,
      });
    }
  }, [coachHandle, getCoachQuery]);

  const coach = getCoachQuery?.data?.getCoach;

  return (
    <div className="my-10 w-screen">
      <div className="mx-auto">
        <HtmlMeta title="Enquiry Booking" />
        <div className="flex flex-col justify-between items-center w-full px-2 xl:px-0 xl:w-[1000px] mx-auto">
          <div className="rounded-lg w-full px-4 py-6">
            <ProgressBar progress={9} />
            <div className="flex flex-col items-center justify-center mt-6">
              {getCoachQuery.isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <div className="w-full xl:w-[964px] px-2 xl:px-0 mx-auto pb-8">
                    <div className="w-full flex flex-col items-center">
                      <div className="flex self-start">
                        <Button
                          leftIcon={<ArrowLeft />}
                          className="bg-bottleGreen border-bottleGreen border-solid text-white w-fit cursor-pointer z-10"
                          onClick={() => navigate(-1)}
                        >
                          Back to results
                        </Button>
                      </div>
                      <div className="w-full text-center lg:mt-[-40px]">
                        <h3>
                          Select a time below for your first session with {coach?.displayName}
                        </h3>
                        <p className="text-lg mb-2">
                          The date and time selected will be held for 15 minutes.
                        </p>
                      </div>
                    </div>
                    <section className="grid w-full grid-flow-row md:grid-flow-col bg-white md:bg-black m-0 items-center outline outline-1 outline-solid outline-skin rounded-2xl">
                      <div className="h-[100%] w-full md:w-fit bg-white md:bg-black flex flex-col items-center justify-center border rounded-full md:rounded-l-2xl md:rounded-r-none mt-4 md:m-0">
                        <img
                          src={decodeURIComponent(
                            `${app.CLOUDFRONT.USER_ASSETS}/${coach.id}/thumbnail.jpg`,
                          )}
                          alt={coach?.displayName ?? "Profile photo"}
                          className="object-cover aspect-w-1 aspect-h-1 w-[100px] h-[100px] md:w-[270px] md:h-[270px] rounded-full md:rounded-l-2xl md:rounded-r-none"
                        />
                      </div>
                      <div className="p-4 w-full md:w-[calc(100vw-270px)] xl:w-[694px] h-full bg-white rounded-xl md:rounded-r-xl md:rounded-l-none">
                        <div>
                          <div className="w-full flex flex-col md:flex-row justify-start md:justify-between md:items-center">
                            <h1 className="text-2xl font-semibold mb-0 whitespace-nowrap">
                              {coach.displayName}
                            </h1>
                            <CoachTestimonials coach={coach} />
                          </div>
                          <h2 className="text-xl font-medium">{coach?.headline}</h2>
                          {coach?.bio && <CoachBio bio={coach?.bio} />}
                          {coach?.trainingSchool && <h4>Trained at {coach.trainingSchool}</h4>}

                          <div className="flex gap-x-4 justify-between">
                            <div className="w-[45%]">
                              <LabelList
                                header="Focus"
                                list={
                                  coach.specialisms.length
                                    ? coach!.specialisms!.slice(0, 2)
                                    : [{ label: "Generalist" }]
                                }
                              />
                            </div>
                            <div className="w-[45%]">
                              <LabelList
                                header="Approach"
                                list={
                                  coach.approaches.length
                                    ? coach!.approaches!.slice(0, 2)
                                    : [{ label: "Flexible" }]
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <div className="text-center mt-4">
                      <p className="text-lg mb-2 italic">
                        The calendar below shows your coach&apos;s availability for the next few
                        months, be sure to check future dates to ensure their availabilty aligns
                        with your own for future sessions. You can change your coach at any time
                        during your programme.
                      </p>
                      {coach?.holiday && (
                        <div className="w-full flex justify-center text-center pt-2">
                          <h5 className="!bg-skin p-2 rounded-lg">
                            {coach!.displayName} is away from{" "}
                            {DateTime.fromISO(coach?.holidayStart).toFormat("ccc dd MMMM")} until{" "}
                            {DateTime.fromISO(coach?.holidayEnd).toFormat("ccc dd MMMM")}
                          </h5>
                        </div>
                      )}
                    </div>

                    <SchedulerProvider>
                      <Schedule
                        coachId={getCoachQuery?.data?.getCoach?.id}
                        coachHandle={coachHandle}
                      />
                    </SchedulerProvider>
                  </div>

                  <div className="mt-10 flex flex-col items-center text-center md:w-[60%] mx-4 md:mx-auto gap-y-8">
                    <img src="/images/user_tools/appraisals.svg" alt="img" />
                    <div>
                      <h2 className="text-4xl">We have 100+ coaches waiting for you</h2>
                      <p className="text-lg">
                        If this coach doesn&apos;t look right for you, that&apos;s okay!
                      </p>
                      <p className="text-lg">
                        If you&apos;d like to see different coaches, you can select another coach or
                        browse all our available coaches from the previous page.
                      </p>
                    </div>
                    <div className="flex flex-col md:flex-row gap-4">
                      <Button
                        leftIcon={<ArrowLeft />}
                        large
                        className="bg-bottleGreen border-bottleGreen border-solid text-white w-full md:w-fit"
                        onClick={() => navigate(-1)}
                      >
                        Back to results
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
