import { Button } from "$/components/Button";
import { CoachBio } from "$/components/CoachBio";
import { LabelList } from "$/components/CoachCard";
import CoachTestimonials from "$/components/CoachCard/CoachTestimonials";
import { Modal } from "$/components/Modal";
import { app } from "$/configs/app.config";
import { Coach, useCoachAvailabilityQuery } from "$/graphql/types.generated";
import { decode } from "html-entities";
import { Mixpanel } from "$/tracking";
import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  format,
  isBefore,
  parseISO,
  startOfDay,
} from "date-fns";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";

export default function ResultCoachCard({
  coach,
  onSelect,
}: {
  coach: Coach;
  onSelect: () => void;
}) {
  const [readMore, setReadMore] = useState(false);
  const { data, isLoading } = useCoachAvailabilityQuery({
    coachId: coach.id,
    startsAt: addDays(startOfDay(new Date()), 1).toISOString(),
    endsAt: addMonths(endOfDay(new Date()), 1).toISOString(),
  });

  const [availability, setAvailability] = useState<{
    nextSession: string;
    sessionsThisWeek: number;
    sessionsThisMonth: number;
  }>();

  useEffect(() => {
    if (data) {
      const ordered = orderBy(data.coachAvailability, ["startsAt"], ["asc"]);
      const nextSession = ordered[0];
      const sessionsThisWeek = ordered.filter((session) =>
        isBefore(parseISO(session.startsAt), addWeeks(endOfDay(new Date()), 1)),
      );

      setAvailability({
        nextSession: nextSession?.startsAt
          ? format(parseISO(nextSession.startsAt), "dd/MM/yyyy ha").toLowerCase()
          : "N/A",
        sessionsThisWeek: sessionsThisWeek.length,
        sessionsThisMonth: ordered.length,
      });
    }
  }, [data, isLoading]);

  return (
    <div className={"flex flex-col bg-white rounded-2xl min-h-[500px]"}>
      <div>
        <img
          src={`${app.CLOUDFRONT.USER_ASSETS}/${coach.id}/thumbnail.jpg`}
          alt="profile"
          className={"w-full md:w-[250px] rounded-t-2xl rounded-b-none object-cover"}
          height={"250px"}
        />
      </div>
      <div className={"p-3 text-left flex flex-col grow justify-between gap-2 w-[250px]"}>
        <div className="flex flex-col justify-between h-full">
          <div>
            <div className="w-full flex flex-row md:flex-col h-[60px] justify-between items-center md:justify-start md:items-start">
              <h1 className="max-w-full text-xl font-semibold mb-0 pb-2 overflow-hidden text-ellipsis whitespace-nowrap">
                {coach.displayName}
              </h1>
              <CoachTestimonials coach={coach} />
            </div>
            <p className="min-h-[60px] text-sm md:text-lg mt-2 mb-3 line-clamp-2">
              {coach.headline}
            </p>
            <p className="m-0">
              <span className="font-semibold">Next slot:</span> {availability?.nextSession}
            </p>
            {coach?.totalApproachMatches && (
              <p className="m-0">
                <span className="font-semibold">Matching experiences:</span>{" "}
                {coach?.totalApproachMatches}
              </p>
            )}
            {coach?.totalEventMatches && (
              <p className="m-0">
                <span className="font-semibold">Slots fitting your schedule:</span>{" "}
                {coach?.totalEventMatches}
              </p>
            )}
          </div>
          <div className="flex justify-between items-center mt-2">
            <div
              onClick={() => setReadMore(true)}
              className="underline cursor-pointer"
              id="enquiry-coach-card-read-more"
            >
              Read More
            </div>

            <Button
              primary
              onClick={() => {
                Mixpanel.track("Enquiry: Select coach");
                onSelect();
              }}
              id="enquiry-select-coach"
            >
              Select this coach
            </Button>
          </div>
        </div>
      </div>
      {readMore && (
        <Modal size="lg" onClose={() => setReadMore(false)}>
          <div>
            <div className={"p-3"}>
              <div className="w-full flex flex-col md:flex-row justify-start md:justify-between md:items-center">
                <h1 className="text-2xl font-semibold mb-0 whitespace-nowrap">
                  {coach.displayName}
                </h1>
              </div>
              <h3>{decode(coach!.headline)}</h3>
              <div>
                <CoachBio bio={coach!.bio as string} />
              </div>
              {coach?.trainingSchool && <h4>Trained at {coach.trainingSchool}</h4>}
              <div className="pt-2 pb-4">
                <LabelList header="Approach" list={coach!.approaches!} />
                <LabelList header="Specialisms" list={coach!.specialisms!} />
                <LabelList header="Experience" list={coach!.audienceExperiences!} />
              </div>
              <div className="pb-2">
                <span className="font-semibold">Next available slot:</span>{" "}
                {availability?.nextSession}
              </div>
              <div className="pb-2">
                <span className="font-semibold">Available slots in next 7 days:</span>{" "}
                {availability?.sessionsThisWeek}
              </div>
              <div className="pb-2">
                <span className="font-semibold">Available slots in next 30 days:</span>{" "}
                {availability?.sessionsThisMonth}
              </div>
              {coach?.totalApproachMatches && (
                <p className="m-0 pb-2">
                  <span className="font-semibold">Matching experiences:</span>{" "}
                  {coach?.totalApproachMatches}
                </p>
              )}
              {coach?.totalEventMatches && (
                <p className="m-0 pb-2">
                  <span className="font-semibold">
                    Slots fitting your schedule in the next 7 days:
                  </span>{" "}
                  {coach?.totalEventMatches}
                </p>
              )}
              {coach?.holiday && (
                <div className="flex">
                  <h5 className="!bg-skin p-2 rounded-lg">
                    {coach!.displayName} is away from{" "}
                    {DateTime.fromISO(coach?.holidayStart).toFormat("ccc dd MMMM")} until{" "}
                    {DateTime.fromISO(coach?.holidayEnd).toFormat("ccc dd MMMM")}
                  </h5>
                </div>
              )}
            </div>

            <div className="flex justify-end">
              <Button
                primary
                large
                onClick={() => {
                  Mixpanel.track("Enquiry: Select coach");
                  onSelect();
                }}
                id="enquiry-select-coach"
              >
                Select this coach
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
