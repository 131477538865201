import { Layout } from "antd";
import { HtmlMeta } from "$/components/HtmlMeta";
import { IPageBaseProps } from "$/interfaces";
import { Stars } from "$/components/Icons/Stars";
import YouTube from "react-youtube";
import { ProgrammeChoiceCard } from "../Sessions/BookSession/components/ProgrammeChoiceCard";
import { SessionChoiceCard } from "../Sessions/BookSession/components/SessionChoiceCard";
import LandingBanner from "$/components/Banner/LandingBanner";
import { Mixpanel } from "$/tracking";
import { useUser } from "$/state/user";

interface IProps extends IPageBaseProps {}

export const Landing: React.FC<IProps> = () => {
  const user = useUser();

  const videoHref = user.currentUser
    ? "/101"
    : "https://www.youtube.com/channel/UCjE-lGzfNGK4Max2bUd6oqg";

  return (
    <Layout>
      <HtmlMeta title="More Happi Landing" />
      <LandingBanner />
      <div className="w-full flex flex-col justify-center items-center py-10 gap-y-16">
        <div className="flex w-[90%] flex-col items-center bg-white rounded-xl">
          <div className="flex w-full flex-col md:flex-row">
            <div className="w-full md:w-1/2 flex flex-col p-8">
              <h2 className="text-4xl">More Happi coaching explained</h2>
              <p className="text-xl">
                Equip yourself for every challenge that comes your way with unlimited 1:1 online
                coaching. Designed for your personal and professional development.
              </p>
              <p>
                <i>
                  “Coaching is like having a personal trainer for your career and life goals. We
                  believe that coaching has the power to transform lives AND businesses.”
                </i>
                <br />
                <span className="font-semibold">Ashleigh Tennent, founder More Happi</span>
              </p>
              <div className="flex">
                <Stars stars="4.7" />
                <span className="font-semibold ml-1">4.7</span>/5 from 4,329 reviews
              </div>
            </div>
            <div className="w-full md:w-1/2 flex flex-col justify-around p-8">
              <h2 className="text-xl">
                Have specific questions you want answered? Find them here:
              </h2>
              <div
                onClick={() => {
                  document
                    .getElementById("what-is-coaching")
                    ?.scrollIntoView({ behavior: "smooth", block: "center" });
                  Mixpanel.track("Clicked Heading - What is coaching", { from: "Landing" });
                }}
                className="text-lg m-0 underline cursor-pointer"
              >
                What is coaching?
              </div>
              <div
                onClick={() => {
                  document
                    .getElementById("how-coaching-helps")
                    ?.scrollIntoView({ behavior: "smooth", block: "center" });
                  Mixpanel.track("Clicked Heading - How coaching helps", { from: "Landing" });
                }}
                className="text-lg m-0 underline cursor-pointer"
              >
                How could coaching support my growth and development?
              </div>
              <div
                onClick={() => {
                  document
                    .getElementById("topics-for-coaching")
                    ?.scrollIntoView({ behavior: "smooth", block: "center" });
                  Mixpanel.track("Clicked Heading - Topics for coaching", { from: "Landing" });
                }}
                className="text-lg m-0 underline cursor-pointer"
              >
                What type of topics do people bring to coaching?
              </div>
              <div
                onClick={() => {
                  document
                    .getElementById("typical-coaching-session")
                    ?.scrollIntoView({ behavior: "smooth", block: "center" });
                  Mixpanel.track("Clicked Heading - Typical coaching session", { from: "Landing" });
                }}
                className="text-lg m-0 underline cursor-pointer"
              >
                What does a typical coaching session look like?
              </div>
              <div
                onClick={() => {
                  document
                    .getElementById("session-preparation")
                    ?.scrollIntoView({ behavior: "smooth", block: "center" });
                  Mixpanel.track("Clicked Heading - Session preparation", { from: "Landing" });
                }}
                className="text-lg m-0 underline cursor-pointer"
              >
                Do I need to prepare anything for my session?
              </div>
              <div
                onClick={() => {
                  document
                    .getElementById("where-to-start")
                    ?.scrollIntoView({ behavior: "smooth", block: "center" });
                  Mixpanel.track("Clicked Heading - Where to start", { from: "Landing" });
                }}
                className="text-lg m-0 underline cursor-pointer"
              >
                Where should I start?
              </div>
              <div
                onClick={() => {
                  document
                    .getElementById("coaching-options")
                    ?.scrollIntoView({ behavior: "smooth", block: "center" });
                  Mixpanel.track("Clicked Heading - Coaching options", { from: "Landing" });
                }}
                className="text-lg m-0 underline cursor-pointer"
              >
                What options are there to work with a coach?
              </div>
            </div>
          </div>
          <div className="w-9/10 min-w-[90%] bg-yellow rounded-xl flex p-4 md:mb-6 gap-x-4 flex-wrap justify-center">
            <p className="m-0 text-darkGreen text-lg pb-2 md:pb-0">Trusted by these teams</p>
            <div className="flex gap-x-4 flex-wrap justify-center">
              <img src="/images/logos/Minor-Figures.png" alt="logo" className="h-5 mt-1" />
              <img src="/images/logos/Hello-fresh.png" alt="logo" className="h-5 mt-1" />
              <img src="/images/logos/Moo.png" alt="logo" className="h-5 mt-1" />
              <img src="/images/logos/Daye.png" alt="logo" className="h-5 mt-2" />
              <img src="/images/logos/Beam.png" alt="logo" className="h-5 mt-1" />
              <img src="/images/logos/Zinc.png" alt="logo" className="h-5 mt-1" />
              <img src="/images/logos/Worklife.png" alt="logo" className="h-5 mt-1" />
              <img src="/images/logos/Yulife.png" alt="logo" className="h-5 mt-1" />
              <img src="/images/logos/Ultraleap.png" alt="logo" className="h-5 mt-1" />
              <img src="/images/logos/Seon.png" alt="logo" className="h-5 mt-1" />
            </div>
          </div>
        </div>

        <div className="flex w-3/4 flex-col items-center gap-y-4">
          <div className="w-full flex flex-col md:flex-row">
            <div className="w-full md:w-2/3 flex flex-col">
              <h3 id="what-is-coaching" className="text-2xl">
                What is coaching?
              </h3>
              <p className="text-lg">
                Simply put, it&apos;s a private, 1:1, unbiased conversation with a trained
                professional (your coach) to untangle thoughts, challenges, ideas, and turn them
                into actions.
              </p>
              <p className="text-lg">
                Through these conversations, you can move forward with clarity and direction. Now
                you can achieve more with less time so you can get to where you want to be, faster.
              </p>
            </div>
            <div className="w-full md:w-1/3 flex flex-col m-auto text-end md:ml-4">
              <YouTube
                videoId={"4i9J1s1gEVw"}
                className="onboarding_video rounded-xl"
                id="onboarding_video"
              />
              <a href={videoHref} target="_blank" rel="noreferrer">
                View more of our introduction videos here
              </a>
            </div>
          </div>

          <div className="flex w-full md:w-3/4 object-contain">
            <img src="/images/landing/Actions.png" alt="actions" className="max-w-full" />
          </div>

          <div className="w-full flex flex-col md:flex-row">
            <div className="w-full md:w-2/3 flex flex-col">
              <h3 id="how-coaching-helps" className="text-2xl">
                How could coaching support my growth and development?
              </h3>
              <p className="text-lg">
                This is not your average &quot;perk&quot;. <br /> More Happi is an essential career
                growth and development tool to help you:
              </p>
              <ul className="flex flex-col text-lg gap-y-2">
                <li>✔️ Achieve goals</li>
                <li>✔️ Develop new skills</li>
                <li>✔️ Increase your self-awareness</li>
                <li>✔️ Solve problems, and more!</li>
              </ul>
            </div>
            <div className="w-full md:w-1/3 flex flex-col m-auto text-end md:ml-4">
              <YouTube
                videoId={"yTVdJOgpze8"}
                className="onboarding_video rounded-xl"
                id="onboarding_video"
              />
              <a href={videoHref} target="_blank" rel="noreferrer">
                View more of our introduction videos here
              </a>
            </div>
          </div>

          <div className="w-[90%] flex flex-col items-center justify-center gap-y-6 md:py-6">
            <h3 id="topics-for-coaching" className="text-2xl m-0 text-center">
              Our trained coaches can help you with these topics and more:
            </h3>
            <div className="flex flex-col md:flex-row w-full md:w-auto justify-around gap-6 text-center">
              <div className="bg-white rounded-full p-4">
                <p className="text-lg m-0">Solve overwhelm and burnout</p>
              </div>
              <div className="bg-white rounded-full p-4">
                <p className="text-lg m-0">Cope with self doubt</p>
              </div>
              <div className="bg-white rounded-full p-4">
                <p className="text-lg m-0">Resolve conflicts and communicate better</p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full md:w-auto justify-around gap-6 text-center">
              <div className="bg-white rounded-full p-4">
                <p className="text-lg m-0">Use your time more effectively</p>
              </div>
              <div className="bg-white rounded-full p-4">
                <p className="text-lg m-0">Look after your wellbeing</p>
              </div>
              <div className="bg-white rounded-full p-4">
                <p className="text-lg m-0">Find more confidence in new situations</p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full md:w-auto justify-around gap-6 text-center">
              <div className="bg-white rounded-full p-4">
                <p className="text-lg m-0">Build resilience</p>
              </div>
              <div className="bg-white rounded-full p-4">
                <p className="text-lg m-0">Solve problems and make clear decisions</p>
              </div>
              <div className="bg-white rounded-full p-4">
                <p className="text-lg m-0">Reach career goals</p>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row">
            <div className="w-full md:w-2/3 flex flex-col">
              <h3 id="typical-coaching-session" className="text-2xl">
                What does a typical coaching session look like?
              </h3>
              <p className="text-lg">
                Coaching is non-directive, meaning your coach won&apos;t necessarily be the subject
                matter expert of the topic that you&apos;ve brought to your session. Instead, they
                will use powerful questioning techniques and active listening to guide you towards
                your own insights and solutions - providing you with a &quot;safe space&quot; to
                learn and grow.
              </p>
              <p className="text-lg">
                Our sessions are 45-minute, confidential, online sessions where you&apos;ll meet
                your coach and work with them to:
              </p>
              <ul className="flex flex-col text-lg">
                <li>Set goals</li>
                <li>Explore perspectives</li>
                <li>Create action plans</li>
                <li>Celebrate progress</li>
              </ul>
              <p className="text-lg">
                No-one at your organisation or More Happi will know what you discuss with your
                coach. This is your safe space to unpack problems, ideas or goals that are important
                to you.
              </p>
            </div>
            <div className="w-full md:w-1/3 flex flex-col m-auto text-end md:ml-4">
              <YouTube
                videoId={"RDPTf2LZhAo"}
                className="onboarding_video rounded-xl"
                id="onboarding_video"
              />
              <a href={videoHref} target="_blank" rel="noreferrer">
                View more of our introduction videos here
              </a>
            </div>
          </div>

          <div className="w-[90%] flex flex-col items-center justify-center gap-y-6">
            <div className="flex flex-col md:flex-row justify-around gap-x-6">
              <div className="flex flex-col justify-center items-center text-center bg-yellow rounded-full p-4 h-60 w-60">
                <p className="text-5xl font-bold m-0">73%</p>
                <p className="text-lg m-0">Better work:life balance*</p>
              </div>
              <div className="flex flex-col justify-center items-center text-center bg-darkGreen text-white rounded-full p-4 h-60 w-60">
                <p className="text-5xl font-bold m-0">97%</p>
                <p className="text-lg m-0">Of our customers rate their coach 4 or 5 out of 5</p>
              </div>
              <div className="flex flex-col justify-center items-center text-center bg-white rounded-full p-4 h-60 w-60">
                <p className="text-5xl font-bold m-0">65%</p>
                <p className="text-lg m-0">
                  That&apos;s how much happier customers are after 1 session
                </p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-around gap-x-6 mt-[-24px] md:mt-0">
              <div className="flex flex-col justify-center items-center text-center bg-darkGreen text-white rounded-full p-4 h-60 w-60">
                <p className="text-5xl font-bold m-0">80%</p>
                <p className="text-lg m-0">Increase in self confidence*</p>
              </div>
              <div className="flex flex-col justify-center items-center text-center bg-white rounded-full p-4 h-60 w-60">
                <p className="text-5xl font-bold m-0">72%</p>
                <p className="text-lg m-0">Better communication skills*</p>
              </div>
              <div className="flex flex-col justify-center items-center text-center bg-yellow rounded-full p-4 h-60 w-60">
                <p className="text-5xl font-bold m-0">70%</p>
                <p className="text-lg m-0">Increased work performance*</p>
              </div>
            </div>
            <p className="text-center">
              Source: *Institute of Coaching, ICF Global Coaching Study 2009
            </p>
          </div>

          <div className="w-full flex flex-col md:flex-row md:pt-6">
            <div className="w-full md:w-2/3 flex flex-col">
              <h3 id="session-preparation" className="text-2xl">
                Do I need to prepare anything for my session?
              </h3>
              <p className="text-lg">
                Giving it some thought in advance will certainly help you get the most out of it,
                but your coach can help you figure this out during the session. You should aim to
                come with something to talk about - an area of work or life that you&apos;d like to
                improve, or where you&apos;d like something to be different or better.
              </p>

              <ul className="flex flex-col text-lg gap-y-2">
                <li>
                  Choose a quiet environment where you&apos;re confident that you won&apos;t be
                  interrupted.
                </li>
                <li>
                  Bring something to take notes with: a notebook, or digital notes if you prefer.
                </li>
                <li>Come with an open mind ready for discussion.</li>
              </ul>
            </div>
            <div className="w-full md:w-1/3 flex flex-col m-auto text-end md:ml-4">
              <YouTube
                videoId={"ckJLZKSNAzQ"}
                className="onboarding_video rounded-xl"
                id="onboarding_video"
              />
              <a href={videoHref} target="_blank" rel="noreferrer">
                View more of our introduction videos here
              </a>
            </div>
          </div>

          <div className="hidden md:flex w-full md:w-3/4 object-contain">
            <img src="/images/landing/Topics.png" alt="topics" className="max-w-full rounded-2xl" />
          </div>

          <div className="w-full flex flex-col md:flex-row">
            <div className="w-full md:w-2/3 flex flex-col pt-6">
              <h3 id="where-to-start" className="text-2xl">
                Where should I start?
              </h3>
              <p className="text-lg">
                <i>Psst! </i>We&apos;ll let you into a secret... few people know where to start.
                That&apos;s where coaching comes in mighty handy (and why so many successful people
                swear by it).
              </p>
              <p className="text-lg">
                Don&apos;t worry—you don&apos;t need to come with a big problem to work on. It can
                be something as simple as wanting to use your time more effectively or feel more
                motivated and less stressed.
              </p>
              <p className="text-lg">
                If you&apos;re really stuck, we recommend that you use our{" "}
                <a
                  href="https://midnight-revolve-64c.notion.site/More-Happi-Goal-Planner-80d2046f54b141b5b02d5794ad08685c"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  quick and easy tool
                </a>{" "}
                to assess where you&apos;re at and identify the areas where you&apos;d like to make
                improvements.
              </p>
              <p className="text-lg">
                No prep. <br />
                No judgement. <br />
                Come with your mess. Leave with clear next steps.
              </p>
            </div>
            <div className="w-full md:w-1/3 flex flex-col m-auto text-end md:ml-4">
              <YouTube
                videoId={"r0A-q8TGssk"}
                className="onboarding_video rounded-xl"
                id="onboarding_video"
              />
              <a href={videoHref} target="_blank" rel="noreferrer">
                View more of our introduction videos here
              </a>
            </div>
          </div>

          <div className="flex w-full md:w-3/4 object-contain">
            <img
              src="/images/landing/Clarity.png"
              alt="clarity"
              className="max-w-full rounded-2xl"
            />
          </div>

          <div className="flex w-full">
            <div className="w-full flex flex-col">
              <h3 id="coaching-options" className="text-2xl">
                What options are there to work with a coach?
              </h3>
              <p className="text-lg">
                There are two different types of coaching offered at More Happi - Coaching
                Programmes and Single Sessions.
              </p>
              <div className="w-full flex flex-col md:flex-row justify-around pt-6 gap-8 md:gap-y-0">
                <ProgrammeChoiceCard />
                <SessionChoiceCard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
