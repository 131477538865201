import {
  EnquiryState,
  ExplainImage,
  ProgrammeBreakdown,
  ProgrammeChoice,
  ProgrammeDetails,
  ProgrammeTextInput,
  ProgrammeTitleSubtitle,
} from "./helpers/storyblok";

export const introState = (data: ProgrammeTitleSubtitle) => {
  return {
    type: "intro",
    title: data.title,
    subtitle: data.subtitle,
    next: "why",
  };
};

export const whyState = (data: ProgrammeChoice) => {
  return {
    type: "multi-choice",
    includeOther: true,
    title: data.title,
    subtitle: data.subtitle,
    options: data.options.map((option) => ({ label: option })),
    next: "programme",
  };
};

export const coachExplainState = (data: ExplainImage) => {
  const body = (
    <div className="w-full flex items-center">
      <img src={data.image} alt="" className="w-full md:w-auto md:mx-auto" />
    </div>
  );

  return {
    type: "explain-image",
    title: data.title,
    subtitle: data.subtitle,
    body,
    next: "someoneExperiencedWith",
  };
};

// export const howWillYouKnowState = (data: ProgrammeTextInput) => {
//   return {
//     type: "text-input",
//     includeInput: true,
//     title: data.title,
//     subtitle: data.subtitle,
//     placeholder: data.placeholder,
//     next: "programme",
//   };
// };

const ProgrammeCard = ({ step, title, body }) => {
  return (
    <div className="rounded-2xl bg-white p-4 text-center flex flex-col items-center mx-auto w-full max-w-[600px] gap-2">
      <div className="bg-yellow rounded-full h-9 w-9 flex items-center justify-center text-xl">
        {step}
      </div>
      <h2 className="max-w-[500px] text-xl mb-0">{title}</h2>
      <p className="max-w-[500px] text-xl mb-0">{body}</p>
    </div>
  );
};

export const programmeState = (data: ProgrammeBreakdown) => {
  const body = (
    <div className="flex flex-col gap-4 w-full mx-2">
      {data.breakdown.map((step, index) => (
        <ProgrammeCard key={index} step={index + 1} title={step.title} body={step.subtitle} />
      ))}
    </div>
  );
  return {
    type: "explain-image",
    title: data.title,
    subtitle: data.subtitle,
    body,
    next: "coachExplain",
  };
};

export const someoneExperiencedWithState = () => {
  const title = "What kind of coach would you like to work with?";
  const subtitle = "Choose as many as you like";
  const next = "whatDay";
  const options = [
    { label: "Someone firm and structured" },
    { label: "Someone creative and exploratory" },
    { label: "Someone experienced with neurodivergent people" },
    { label: "Someone experienced with women" },
    { label: "Someone experienced with leaders" },
    { label: "Someone experienced with commercial employees" },
    { label: "Someone experienced with board members" },
    { label: "Someone experienced with managing directors / CEOs" },
    { label: "Someone experienced with first time managers" },
    { label: "Someone experienced with managers" },
    { label: "Someone experienced with team leads" },
    { label: "I'm flexible" },
  ];

  return {
    type: "multi-choice",
    title,
    subtitle,
    options,
    next,
  };
};

export const whatDayState = () => {
  const title = "When would you prefer to have your first session?";
  const subtitle = "Choose as many as you like.";
  const next = "results";

  const options = [
    {
      label: "Monday",
      day: [
        { label: "Monday Morning", value: "Monday-Morning" },
        { label: "Monday Afternoon", value: "Monday-Afternoon" },
        { label: "Monday Evening", value: "Monday-Evening" },
      ],
    },
    {
      label: "Tuesday",
      day: [
        { label: "Tuesday Morning", value: "Tuesday-Morning" },
        { label: "Tuesday Afternoon", value: "Tuesday-Afternoon" },
        { label: "Tuesday Evening", value: "Tuesday-Evening" },
      ],
    },
    {
      label: "Wednesday",
      day: [
        { label: "Wednesday Morning", value: "Wednesday-Morning" },
        { label: "Wednesday Afternoon", value: "Wednesday-Afternoon" },
        { label: "Wednesday Evening", value: "Wednesday-Evening" },
      ],
    },
    {
      label: "Thursday",
      day: [
        { label: "Thursday Morning", value: "Thursday-Morning" },
        { label: "Thursday Afternoon", value: "Thursday-Afternoon" },
        { label: "Thursday Evening", value: "Thursday-Evening" },
      ],
    },
    {
      label: "Friday",
      day: [
        { label: "Friday Morning", value: "Friday-Morning" },
        { label: "Friday Afternoon", value: "Friday-Afternoon" },
        { label: "Friday Evening", value: "Friday-Evening" },
      ],
    },
    {
      label: "Saturday",
      day: [
        { label: "Saturday Morning", value: "Saturday-Morning" },
        { label: "Saturday Afternoon", value: "Saturday-Afternoon" },
        { label: "Saturday Evening", value: "Saturday-Evening" },
      ],
    },
    {
      label: "Sunday",
      day: [
        { label: "Sunday Morning", value: "Sunday-Morning" },
        { label: "Sunday Afternoon", value: "Sunday-Afternoon" },
        { label: "Sunday Evening", value: "Sunday-Evening" },
      ],
    },
  ];

  return {
    type: "select-date",
    title,
    subtitle,
    options,
    next,
  };
};

export const whatTime = () => {
  const next = "results";
  const title = "Would you prefer morning, afternoon or evening sessions?";
  const subtitle = "Choose as many as you like";
  const options = [{ label: "Morning" }, { label: "Afternoon/Evening" }, { label: "I'm flexible" }];

  return {
    type: "multi-choice",
    title,
    subtitle,
    options,
    next,
  };
};

const chooseAPackageState = (data: ProgrammeDetails) => {
  return {
    data,
    type: "package",
    next: "results",
  };
};

export const enquirySections = {
  "what-you-want": ["path", "why"],
  "our-programmes": ["programme"],
  "your-coach": ["coachExplain", "someoneExperienceWith", "whatDay", "whatTime"],
  "select-programme": ["results", "package"],
};

export const getEnquiryStates = (data: EnquiryState) => [
  { state: "path", data: introState(data.intro) },
  { state: "why", data: whyState(data?.why) },
  // {
  //   state: "howWillYouKnow",
  //   data: howWillYouKnowState(data?.howWillYouKnow),
  // },
  { state: "programme", data: programmeState(data?.breakdown) },
  { state: "coachExplain", data: coachExplainState(data?.coachExplain) },
  { state: "someoneExperiencedWith", data: someoneExperiencedWithState() },
  { state: "whatDay", data: whatDayState() },
  { state: "whatTime", data: whatTime() },
  { state: "package", data: chooseAPackageState(data?.programmes) },
];
