import React, { useEffect, useState } from "react";
import { HtmlMeta } from "$/components/HtmlMeta";
import { useScrollTo } from "$/hooks";
import { IPageBaseProps } from "$/interfaces";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getEnquiryStates } from "./states";
import {
  useCreateEnquiryMutation,
  UserRole,
  useUpsertEnquiryChoiceMutation,
} from "$/graphql/types.generated";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { CurrentUser, useUser } from "$/state/user";
import { useCompanySubscription } from "$/state/company-subscription";
import { useFeatures } from "$/state/features";
import { ProgressBar } from "./components/ProgressBar";
import { SingleChoice } from "./components/SingleChoice";
import { MultiChoice } from "./components/MultiChoice";
import { TextInput } from "./components/TextInput";
import { ExplainImage } from "./components/ExplainImage";
import { v4 as uuid } from "uuid";
import { formatProgrammeData } from "./helpers/storyblok";
import { toast } from "$/components/Toaster";
import Intro from "./components/Intro";
import { useGetProgrammeQuizQuery } from "$/graphql/storyblok/types.generated";
import { Mixpanel } from "$/tracking";
import { CompanySubscriptionState } from "$/state/company-subscription/company-subscription-reducer";
import { EnquirySelectDate } from "./components/EnquirySelectDate";

interface IProps extends IPageBaseProps {}

export type Send = ({ next, value }: { next: string; value: string | Array<string> }) => void;

const initialState = {
  path: { id: null, value: "" },
  why: { id: null, value: [] },
  // howWillYouKnow: { id: null, value: "" },
  someoneExperiencedWith: { id: null, value: [] },
  whatDay: { id: null, value: "" },
  whatTime: { id: null, value: [] },
};

export const canUserAddPackage = (
  user: CurrentUser,
  companySubscription: CompanySubscriptionState,
  features: string[],
) => {
  if (user?.id) {
    const individualWithPackage = user.role === UserRole.Individual && user.packages.length > 0;
    const companyWithSubAndFlag = companySubscription.isActive && features.includes("programmes");
    if (individualWithPackage || companyWithSubAndFlag) {
      return true;
    }
    return false;
  }
  return features.includes("programmesPublic");
};

export const EnquiryPage: React.FC<IProps> = () => {
  useScrollTo();
  const navigate = useNavigate();
  const user = useUser();
  const features = useFeatures();
  const companySubscription = useCompanySubscription();
  const [searchParams, setSearchParams] = useSearchParams();
  const createEnquiry = useCreateEnquiryMutation();
  const upsertEnquiryChoice = useUpsertEnquiryChoiceMutation();
  const [current, setCurrent] = useState(searchParams.get("s") ?? "path");
  const [enquiryId, setEnquiryId] = useState(searchParams.get("eid") ?? null);
  const [programme, setProgramme] = useState<string>(searchParams.get("p") ?? "confidence");
  const [isLoading, setIsLoading] = useState(false);
  const [enquiryState, setEnquiryState] = useState(
    searchParams.get("eid")
      ? JSON.parse(localStorage.getItem(searchParams.get("eid")))
      : initialState,
  );
  const [enquiryStates, setEnquiryStates] = useState(null);
  const programmeQuiz = useGetProgrammeQuizQuery(
    { id: `programmes/${programme}` },
    { enabled: !!programme },
  );

  useEffect(() => {
    setCurrent(searchParams.get("s") ?? "path");
  }, [searchParams.get("s")]);

  useEffect(() => {
    if (programmeQuiz?.data?.PageItem?.content) {
      try {
        setEnquiryStates(
          getEnquiryStates(
            formatProgrammeData(programmeQuiz.data.PageItem.content),
            !!user?.currentUser?.id,
            canUserAddPackage(user.currentUser, companySubscription.state, features),
          ),
        );
      } catch (e) {
        console.error(e);
        toast.error("An error occurred", "");
        setEnquiryStates(null);
      }
    }
  }, [programmeQuiz.data, user.currentUser]);

  const send = async ({ next, value }: { next: string; value: string | Array<string> }) => {
    setIsLoading(true);
    let _enquiryId = enquiryId;

    if (!enquiryState[current]) {
      setSearchParams({ s: next, eid: _enquiryId });
    }

    try {
      if (current === "path") {
        const newEnquiry = await createEnquiry.mutateAsync({
          data: {
            user: user?.currentUser?.id ? { connect: { id: user.currentUser.id } } : undefined,
          },
        });
        setEnquiryId(newEnquiry.createEnquiry.id);
        _enquiryId = newEnquiry.createEnquiry.id;
        setProgramme(value);
      }

      const newEnquiryChoice = await upsertEnquiryChoice.mutateAsync({
        create: {
          enquiry: { connect: { id: _enquiryId } },
          key: current,
          value: Array.isArray(value) ? value.join(",") : value,
        },
        update: {
          enquiry: { connect: { id: _enquiryId } },
          key: current,
          value: Array.isArray(value) ? value.join(",") : value,
        },
        where: {
          id: enquiryState[current].id ?? uuid(),
        },
      });

      if (!enquiryState[current].id) {
        Mixpanel.track(`Enquiry Step: ${current} to ${next}`, {
          programme: current === "path" ? value : programme,
          from: current,
          to: next,
        });
      }

      const newEnquiryState = {
        ...enquiryState,
        [current]: { id: newEnquiryChoice.upsertEnquiryChoice.id, value },
      };
      setEnquiryState(newEnquiryState);
      localStorage.setItem(_enquiryId, JSON.stringify(newEnquiryState));

      if (next === "results") {
        navigate(`/enquiry/results?eid=${_enquiryId}`);
      } else {
        setCurrent(next);
        setSearchParams({
          s: next,
          eid: _enquiryId,
          p: current === "path" ? value : programme ?? value,
        });
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const currentStep = enquiryStates?.find((s) => s.state === current);

  return (
    <div className="w-screen">
      <div className="mx-auto">
        <HtmlMeta title="Enquiry" />
        <div className="flex flex-col justify-between items-center  w-full px-2 xl:px-0 xl:w-[1000px] mx-auto">
          <div className="rounded-lg w-full px-4 py-6">
            <ProgressBar
              progress={enquiryStates?.findIndex((_s) => _s.state === currentStep?.state)}
            />
            <div
              className="flex flex-col items-center justify-center mt-6"
              key={searchParams.get("s")}
            >
              {createEnquiry.isPending || isLoading || !currentStep ? (
                <div className="max-w-[800px] md:w-[800px] h-[500px] flex items-center justify-center">
                  <LoadingSpinner className="!h-12 !w-12" />
                </div>
              ) : (
                <>
                  <div className="max-w-[800px] md:w-[800px] ">
                    {currentStep.data.type === "intro" && (
                      <Intro
                        setProgramme={setProgramme}
                        send={send}
                        {...currentStep.data}
                        key={current}
                      />
                    )}
                    {currentStep.data.type === "single-choice" && (
                      <SingleChoice send={send} {...currentStep.data} />
                    )}
                    {currentStep.data.type === "multi-choice" && (
                      <MultiChoice
                        send={send}
                        {...currentStep.data}
                        value={enquiryState?.[current]?.value}
                        key={current}
                      />
                    )}
                    {currentStep.data.type === "select-date" && (
                      <EnquirySelectDate
                        send={send}
                        {...currentStep.data}
                        value={enquiryState?.[current]?.value}
                        key={current}
                      />
                    )}
                    {currentStep.data.type === "text-input" && (
                      <TextInput
                        send={send}
                        {...currentStep.data}
                        value={enquiryState[current].value}
                      />
                    )}
                  </div>
                  {currentStep.data.type === "explain-image" && (
                    <ExplainImage send={send} {...currentStep.data} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
