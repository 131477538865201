import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { ROUTE_PATHS } from "$/configs/routes";

import { useAuth } from "$/state/auth";

import { PrivateRoute, PublicRoute } from "./routes";

import { SignOut } from "./Auth/SignOut";
import { LoginPage } from "./Auth/Login";
import { Register } from "./Auth/Register";
import { VerifyAccount } from "./Auth/VerifyAccount";
import { ResetPasswordPage } from "./Auth/ResetPassword";
import { ConfirmPasswordReset } from "./Auth/ConfirmPasswordReset";
import { ChangePasswordPage } from "./Auth/ChangePassword";
import { Error404 } from "./Error404/Error404";

import { Home } from "./Home";

import { SupportGuides } from "./SupportGuides";

import { SponsorChecklist } from "./SupportGuides/SponsorChecklist";
import { SponsorChecklistOne } from "./SupportGuides/SponsorChecklist/Checklist/One";
import { SponsorChecklistTwo } from "./SupportGuides/SponsorChecklist/Checklist/Two";
import { SponsorChecklistThree } from "./SupportGuides/SponsorChecklist/Checklist/Three";
import { SponsorChecklistFour } from "./SupportGuides/SponsorChecklist/Checklist/Four";
import { SponsorChecklistFive } from "./SupportGuides/SponsorChecklist/Checklist/Five";
import { SponsorChecklistSix } from "./SupportGuides/SponsorChecklist/Checklist/Six";

import { HowToUse } from "./SupportGuides/HowToUse";
import { WhatIsCoaching } from "./SupportGuides/WhatIsCoaching";
import { OnboardingCallLeaders } from "./SupportGuides/OnboardingCall/Leaders";
import { OnboardingCallTeamMembers } from "./SupportGuides/OnboardingCall/TeamMembers";

import { Tools } from "./Tools";
import { HabitTracker } from "./Tools/HabitTracker";
import { GoalPlanner } from "./Tools/GoalPlanner";

import { MyAccount } from "./MyAccount";
import { ContactUs } from "./ContactUs";

import { Categories } from "./Sessions/Category";
import { BookingSuccess } from "./Sessions/BookingSuccess";
import { BookingIframeRedirect } from "./Sessions/BookingIframeRedirect";
import { Coaches } from "./Sessions/Coaches";
import { Coach } from "./Sessions/Coach";
import { Browse } from "./Sessions/Browse";
import { NextAvailable } from "./Sessions/NextAvailable";
import { WhereToStart } from "./Sessions/WhereToStart";

import { UserSettings } from "./User/Settings";

import { CompanySettings } from "./Company/Settings";
import { CompanyBilling } from "./Company/Billing";
import { ManageTeam } from "./Company/Team";
import { CompanyData } from "./Company/Data";

import { VideoOnboarding } from "./Onboarding";

import { LoadingSpinner } from "$/components/LoadingSpinner";
import { BookingList } from "./Sessions/BookingList";
import { ConfirmBooking } from "./Sessions/Coach/confirm";
import { CancelPage } from "./Sessions/Cancel";
import { CancelConfirmPage } from "./Sessions/Cancel/confirm";
import { VideoOnboardingPlaylist } from "./Onboarding/playlist";
import { NoShowPage } from "./Sessions/NoShow";
import { NoShowConfirmPage } from "./Sessions/NoShow/confirm";
import { CancelCoachPage } from "./Sessions/Cancel/coach";
import { CancelConfirmCoachPage } from "./Sessions/Cancel/coachConfirm";
import { Settings } from "luxon";
import { useUser } from "$/state/user";
import { RegisterIndividual } from "./Auth/Register/individual";
import { Onboarding } from "./Onboarding/Onboarding";
import { CoachesByAvailability } from "./Sessions/CoachesByAvailability";
import { EnquiryPage } from "./Enquiry";
import { EnquiryResultsPage } from "./Enquiry/results";
import { CheckoutPage } from "./Checkout";
import { AddPackagePage } from "./AddPackage";
import { EnquiryBooking } from "./Enquiry/booking";
import { AccountDetails } from "./Enquiry/account";
import { BookNextSessionPage } from "./Sessions/BookNextSession";
import { BookSessionPage } from "./Sessions/BookSession";
import { BookSingleSession } from "./Sessions/BookSession/BookSingleSession";
import { Training } from "./Training";
import { Landing } from "./Landing";

export const Pages = () => {
  const auth = useAuth();
  const user = useUser();

  if (user?.currentUser?.timezone) {
    Settings.defaultZone = user.currentUser.timezone;
  } else if (localStorage.getItem("timezone")) {
    Settings.defaultZone = localStorage.getItem("timezone");
  }

  useEffect(() => {
    auth.initializeUser();
  }, []);

  if (auth.state.isAuthenticating) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path={ROUTE_PATHS.LOGIN}
        element={
          <PublicRoute disableHeader>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.REGISTER}
        element={
          <PublicRoute disableHeader>
            <Register />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.REGISTER_INDIVIDUAL}
        element={
          <PublicRoute disableHeader>
            <RegisterIndividual />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.VERIFY}
        element={
          <PublicRoute disableHeader>
            <VerifyAccount />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.RESET_PASSWORD}
        element={
          <PublicRoute disableHeader>
            <ResetPasswordPage />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.CONFIRM_RESET_PASSWORD}
        element={
          <PublicRoute disableHeader>
            <ConfirmPasswordReset />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.CHANGE_PASSWORD}
        element={
          <PublicRoute disableHeader>
            <ChangePasswordPage />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.ONBOARDING}
        element={
          <PublicRoute evenIfLoggedIn>
            <Onboarding />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.ENQUIRY.MAIN}
        element={
          <PublicRoute evenIfLoggedIn disableHeader disableSubscriptionBanner>
            <EnquiryPage />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.CHECKOUT.MAIN}
        element={
          <PublicRoute evenIfLoggedIn disableHeader disableSubscriptionBanner>
            <CheckoutPage />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.ENQUIRY.RESULTS}
        element={
          <PublicRoute evenIfLoggedIn disableHeader disableSubscriptionBanner>
            <EnquiryResultsPage />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.ENQUIRY.BOOKING}
        element={
          <PublicRoute evenIfLoggedIn disableHeader disableSubscriptionBanner>
            <EnquiryBooking />
          </PublicRoute>
        }
        page="Booking"
      />
      <Route
        path={ROUTE_PATHS.ENQUIRY.ACCOUNT}
        element={
          <PublicRoute evenIfLoggedIn disableHeader disableSubscriptionBanner>
            <AccountDetails />
          </PublicRoute>
        }
        page="Booking"
      />
      <Route
        path={ROUTE_PATHS.ADD_PACKAGE}
        element={
          <PublicRoute evenIfLoggedIn disableHeader disableSubscriptionBanner>
            <AddPackagePage />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTE_PATHS.LANDING}
        element={
          <PublicRoute evenIfLoggedIn disableSubscriptionBanner>
            <Landing />
          </PublicRoute>
        }
      />
      <Route
        exact
        path={ROUTE_PATHS.DASHBOARD}
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
        page="Home"
      />
      <Route
        exact
        path={ROUTE_PATHS.ONBOARDING_VIDEO}
        element={
          <PrivateRoute>
            <VideoOnboarding />
          </PrivateRoute>
        }
        page="Video Onboarding"
      />
      <Route
        exact
        path={ROUTE_PATHS.ONBOARDING_VIDEOS}
        element={
          <PrivateRoute>
            <VideoOnboardingPlaylist />
          </PrivateRoute>
        }
        page="Video Onboarding"
      />
      <Route
        exact
        path={ROUTE_PATHS.TRAINING}
        element={
          <PrivateRoute>
            <Training />
          </PrivateRoute>
        }
        page="Workshop"
      />
      <Route
        exact
        path={ROUTE_PATHS.SUPPORT_GUIDES.MAIN}
        element={
          <PrivateRoute>
            <SupportGuides />
          </PrivateRoute>
        }
        page="Support Guides"
      />
      <Route
        exact
        path={ROUTE_PATHS.TOOLS.MAIN}
        element={
          <PrivateRoute>
            <Tools />
          </PrivateRoute>
        }
        page="Tools"
      />
      <Route
        exact
        path={ROUTE_PATHS.TOOLS.GOAL_PLANNER}
        element={
          <PrivateRoute>
            <GoalPlanner />
          </PrivateRoute>
        }
        page="Tools Goal Planner"
      />
      <Route
        exact
        path={ROUTE_PATHS.TOOLS.HABIT_TRACKER}
        element={
          <PrivateRoute>
            <HabitTracker />
          </PrivateRoute>
        }
        page="Tools Habit Tracker"
      />
      <Route
        exact
        path={ROUTE_PATHS.MY_ACCOUNT}
        element={
          <PrivateRoute>
            <MyAccount />
          </PrivateRoute>
        }
        page="My Account"
      />
      <Route
        exact
        path={ROUTE_PATHS.CONTACT_US}
        element={
          <PrivateRoute>
            <ContactUs />
          </PrivateRoute>
        }
        page="Contact Us"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.MAIN}
        element={
          <PrivateRoute>
            <BookingList />
          </PrivateRoute>
        }
        page="Sessions"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.CANCEL}
        element={
          <PrivateRoute>
            <CancelPage />
          </PrivateRoute>
        }
        page="Sessions"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.CANCEL_CONFIRM}
        element={
          <PrivateRoute>
            <CancelConfirmPage />
          </PrivateRoute>
        }
        page="Sessions"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.CANCEL_COACH}
        element={
          <PublicRoute evenIfLoggedIn>
            <CancelCoachPage />
          </PublicRoute>
        }
        page="Sessions"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.CANCEL_COACH_CONFIRM}
        element={
          <PublicRoute evenIfLoggedIn>
            <CancelConfirmCoachPage />
          </PublicRoute>
        }
        page="Sessions"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.NO_SHOW}
        element={
          <PublicRoute evenIfLoggedIn>
            <NoShowPage />
          </PublicRoute>
        }
        page="Sessions"
      />

      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.NO_SHOW_CONFIRM}
        element={
          <PublicRoute evenIfLoggedIn>
            <NoShowConfirmPage />
          </PublicRoute>
        }
        page="Sessions"
      />

      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.CATEGORY}
        element={
          <PrivateRoute>
            <Categories />
          </PrivateRoute>
        }
        page="Session Category"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.COACHES}
        element={
          <PublicRoute evenIfLoggedIn>
            <Coaches />
          </PublicRoute>
        }
        page="Coaches"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.COACHES_BY_TIME}
        element={
          <PublicRoute evenIfLoggedIn>
            <CoachesByAvailability />
          </PublicRoute>
        }
        page="Coaches"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.BOOK_COACHING.BROWSE}
        element={
          <PrivateRoute evenIfLoggedIn>
            <BookSessionPage />
          </PrivateRoute>
        }
        page="Session Categories"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.BOOK_COACHING.SINGLE}
        element={
          <PrivateRoute evenIfLoggedIn>
            <BookSingleSession />
          </PrivateRoute>
        }
        page="Session Categories"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.BOOK_COACHING.NEXT_AVAILABLE}
        element={
          <PrivateRoute>
            <NextAvailable />
          </PrivateRoute>
        }
        page="Sessions Next Available"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.COACH}
        element={
          <PublicRoute evenIfLoggedIn>
            <Coach />
          </PublicRoute>
        }
        page="Coach"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.CONFIRM_BOOKING}
        element={
          <PrivateRoute>
            <ConfirmBooking />
          </PrivateRoute>
        }
        page="Coach"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.BOOK_NEXT}
        element={
          <PrivateRoute>
            <BookNextSessionPage />
          </PrivateRoute>
        }
        page="Coach"
      />
      <Route
        exact
        path="/booking-success"
        element={
          <PrivateRoute>
            <BookingIframeRedirect />
          </PrivateRoute>
        }
        page="Booking Success"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.BOOKING_SUCCESS}
        element={
          <PrivateRoute>
            <BookingSuccess />
          </PrivateRoute>
        }
        page="Booking Success"
      />
      <Route
        exact
        path={ROUTE_PATHS.SESSIONS.WHERE_TO_START}
        element={
          <PrivateRoute>
            <WhereToStart />
          </PrivateRoute>
        }
        page="Sessions Where To Start"
      />
      <Route
        exact
        path={ROUTE_PATHS.USER.SETTINGS}
        element={
          <PrivateRoute>
            <UserSettings />
          </PrivateRoute>
        }
        page="Account Settings"
      />
      <Route
        exact
        path={ROUTE_PATHS.COMPANY.SETTINGS}
        element={
          <PrivateRoute>
            <CompanySettings />
          </PrivateRoute>
        }
        page="Company Settings"
      />
      <Route
        exact
        path={ROUTE_PATHS.COMPANY.BILLING}
        element={
          <PrivateRoute>
            <CompanyBilling />
          </PrivateRoute>
        }
        page="Billing"
      />
      <Route
        exact
        path={ROUTE_PATHS.COMPANY.MANAGE_TEAM}
        element={
          <PrivateRoute>
            <ManageTeam />
          </PrivateRoute>
        }
        page="Company Manage Team"
      />
      <Route
        exact
        path={ROUTE_PATHS.COMPANY.DATA}
        element={
          <PrivateRoute>
            <CompanyData />
          </PrivateRoute>
        }
        page="Company Data"
      />
      <Route exact path={ROUTE_PATHS.SIGNOUT} element={<SignOut />} page="Sign Out" />
      <Route
        path="*"
        element={
          <PublicRoute disableHeader>
            <Error404 />
          </PublicRoute>
        }
      />
    </Routes>
  );
};
