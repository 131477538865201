import { useIsMobile } from "$/hooks";
import { ArrowRight } from "lucide-react";
import { DateTime } from "luxon";
import { Mixpanel } from "$/tracking";
import { BookColor } from "$/components/Icons";
import { Button } from "$/components/Button";

export interface TrainingCardProps {
  title: string;
  date: string;
  description: string;
  imageSrc: string;
  altText: string;
  sessionType: string;
  href: string;
}

export const TrainingCard = ({
  title,
  date,
  description,
  imageSrc,
  altText,
  sessionType,
  href,
}: TrainingCardProps) => {
  const isMobile = useIsMobile();

  return (
    <div className="max-w-[600px] md:w-[600px]">
      <div className="border border-solid border-lightPurple rounded-xl">
        <div className="border-0 border-b border-solid border-lightPurple flex flex-wrap">
          <section className="w-full md:w-[27.5%] border-0 border-b md:border-b-0 border-solid border-lightPurple py-2 px-4 flex justify-center md:border-r ">
            <div className="flex flex-col md:m-auto">
              <div className="text-lg">{DateTime.fromISO(date).toFormat("ccc")}</div>
              <div className="text-5xl font-bold">
                {DateTime.fromISO(date).toFormat("dd")}
                <span className="text-lg font-normal ml-1">
                  {DateTime.fromISO(date).toFormat("MMM")}
                </span>
              </div>
            </div>
          </section>
          <section className="w-full md:w-[27.5%] border-0 md:border-r border-solid border-lightPurple py-2 px-4 flex justify-center">
            <div className="flex flex-col text-center md:w-full md:m-auto">
              <div className="flex justify-between text-lg md:w-full">
                <p className="m-0">{DateTime.fromISO(date).toFormat("a")}</p>
                <p className="m-0">
                  GMT
                  {DateTime.fromISO(date).offset !== 0 && DateTime.fromISO(date).toFormat("Z")}
                </p>
              </div>
              <div className="text-5xl font-bold">{DateTime.fromISO(date).toFormat("h:mm")}</div>
            </div>
          </section>
          <section className="basis-full md:basis-[45%] text-lg">
            <div className="border-0 border-t md:border-t-0 border-b border-solid border-lightPurple h-1/2 py-2 px-6 flex items-center gap-x-1 justify-center md:justify-start">
              <div className="h-1/2 flex m-auto items-center gap-x-2 justify-center md:justify-start text-black">
                <p className="h-1/2 flex m-auto items-center">
                  <BookColor />
                </p>
                <p className="h-1/2 flex m-auto items-center ">{sessionType}</p>
              </div>
            </div>
            <div className="h-1/2 flex m-auto items-center gap-x-2 justify-center md:justify-start text-black">
              <p className="h-1/2 flex m-auto items-center ">Trainer: Robert Stephenson</p>
            </div>
          </section>
        </div>
        <div className="w-full flex bg-white rounded-b-xl">
          <section className="w-1/3 h-auto rounded-bl-xl flex justify-center">
            <img className="object-contain w-[100%]" src={imageSrc} alt={altText} />
          </section>
          <section className="w-full md:w-2/3 bg-white rounded-b-xl p-2 md:py-4 flex flex-col gap-y-2 justify-between">
            <div className="px-4 min-h-20">
              <h3 className="text-xl md:text-2xl mb-0">{title}</h3>
              <h4 className="pt-4 text-sm md:text-lg">{description}</h4>
            </div>
            <div className="flex justify-end">
              <a href={href}>
                <Button
                  primary
                  small={isMobile}
                  large={!isMobile}
                  className="text-sm md:text-base"
                  onClick={() => Mixpanel.track(`${title} clicked`, { from: "Training" })}
                >
                  <ArrowRight size="1.125rem" />
                  Book your place
                </Button>
              </a>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
