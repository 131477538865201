import React from "react";
import { Layout } from "antd";
import { HtmlMeta } from "$/components/HtmlMeta";
import { IPageBaseProps } from "$/interfaces";
import { ProgrammeChoiceCard } from "./components/ProgrammeChoiceCard";
import { SessionChoiceCard } from "./components/SessionChoiceCard";
import { StarterChoiceCard } from "./components/StarterChoiceCard";
import { useUser } from "$/state/user";
import { OrderByArg, SessionState, useListSessionsQuery } from "$/graphql/types.generated";

interface IProps extends IPageBaseProps {}

export const BookSessionPage: React.FC<IProps> = () => {
  const user = useUser();

  const { data: sessionBooked, isLoading } = useListSessionsQuery({
    where: {
      teamMemberId: { equals: user.currentUser?.teamMember?.id },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
    take: 1,
  });

  return (
    <Layout>
      <HtmlMeta title="Book a session" />
      <div className="w-full flex flex-col justify-center items-center my-10">
        <div className="flex flex-col text-center justify-center px-4">
          <h1 className="text-3xl font-semibold"> Book Coaching</h1>
          <h2 className="text-lg md:text-xl !text-darkGrey  xl:max-w-[750px] xl:mx-auto font-normal">
            Find your next coaching session by selecting from one of the categories below.
          </h2>
        </div>
        <div className="w-3/4 flex flex-col md:flex-row justify-around pt-12 gap-8 md:gap-y-0">
          {!isLoading && !sessionBooked?.listSessions[0] && <StarterChoiceCard />}
          <ProgrammeChoiceCard />
          <SessionChoiceCard />
        </div>
      </div>
    </Layout>
  );
};
