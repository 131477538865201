import { Col, PageHeader, Row, Typography } from "antd";
import { ActionTile } from "$/components/ActionTile";
import { PageHeaderBlock } from "$/components/PageHeaderBlock";
import { useUser } from "$/state/user";
import { useIsMobile, useScrollTo } from "$/hooks";
import { theme } from "$/configs/theme";
import { ROUTE_PATHS } from "$/configs/routes";
import {
  OrderByArg,
  SessionState,
  useListSessionsQuery,
  UserRole,
} from "$/graphql/types.generated";
// import { Mixpanel } from "$/tracking";
// import { useEffect, useState } from "react";
// import { Button } from "$/components/Button";
// import { HalfModal } from "$/components/Modals/HalfModal";

export const SubscriptionDashboard = () => {
  const user = useUser();
  // const [goalPlannerModalOpen, setGoalPlannerModalOpen] = useState(false);

  const { data: futureSession, isLoading } = useListSessionsQuery({
    where: {
      teamMemberId: { equals: user.currentUser?.teamMember?.id },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
    take: 1,
  });

  // useEffect(() => {
  //   const hasFutureSession = futureSession?.listSessions?.length;
  //   const seenNoSessionModal = localStorage.getItem("seenNoSessionModal");

  //   if (
  //     !isLoading &&
  //     !hasFutureSession &&
  //     user?.currentUser?.timezone &&
  //     seenNoSessionModal !== "3"
  //   ) {
  //     setGoalPlannerModalOpen(true);
  //   }
  // }, [isLoading]);

  const sponsorAdminCopy = () => (
    <>
      <Typography.Paragraph>
        Your team has <strong>unlimited</strong> access to coaching support every month. And so do
        you!
      </Typography.Paragraph>
      <Typography.Paragraph>
        <strong>This is not a benefit.</strong> This is an essential career growth and development
        resource to help your team solve problems, achieve goals, develop new skills, increase their
        self-awareness, and more!
      </Typography.Paragraph>
      <Typography.Paragraph>
        As the account owner, your role is to ensure that coaching is an{" "}
        <strong>integral part</strong> of your team&apos;s day-to-day, so they can start to benefit.
        Check out the checklist below to get started.
      </Typography.Paragraph>
    </>
  );

  const teamMemberCopy = () => (
    <>
      <Typography.Paragraph>
        You have unlimited access to coaching support every month!
      </Typography.Paragraph>
      <Typography.Paragraph>
        Coaching is an essential career growth and development resource to help you solve problems,
        achieve goals, develop new skills, increase self-awareness, and be your best self.
      </Typography.Paragraph>
      <Typography.Paragraph>
        But it is not just for work. More Happi coaches can support you in any life area. Think of
        them as your own personal growth champions, getting you closer to the things you want to be,
        do and have.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Set yourself up for success and follow the steps below to get started.
      </Typography.Paragraph>
    </>
  );

  const individualCopy = () => (
    <>
      <Typography.Paragraph>Welcome to your More Happi space.</Typography.Paragraph>
      <Typography.Paragraph>
        With unlimited monthly access, coaching is the best tool for solving overwhelm, burnout,
        challenges, career progression and more.
        <br />
        You can also work with coaches to achieve your goals.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Follow the steps below, or <a href="/coaches">look for your perfect coach now.</a>
      </Typography.Paragraph>
    </>
  );

  const mainCopy =
    user.currentUser?.role === UserRole.Individual
      ? individualCopy()
      : user.currentUser?.isAdmin
      ? sponsorAdminCopy()
      : teamMemberCopy();

  useScrollTo();
  useIsMobile();

  const SPONSOR_SUB_NAVIGATION_ITEMS = [
    {
      id: "id1",
      navTitle: "More Happi 101",
      title: "Tell your team about More Happi",
      to: "https://midnight-revolve-64c.notion.site/1-Tell-your-team-about-More-Happi-baefc4a0fcc24c69830fbf214ecf0a08",
      color: theme.colors.blue,
    },
    {
      id: "id7",
      navTitle: "More Happi Explained",
      title:
        "Share this page with your team to understand how More Happi coaching could help them.",
      to: ROUTE_PATHS.LANDING,
      color: theme.colors.orange,
    },
    // {
    //   id: "id2",
    //   navTitle: "More Happi 101",
    //   title: 'Watch our "How to use Coaching" playlist (10 mins)',
    //   to: ROUTE_PATHS.ONBOARDING_VIDEOS,
    //   color: theme.colors.blue,
    // },
    {
      id: "id3",
      navTitle: "My Account",
      title: "Manage my team.",
      to: ROUTE_PATHS.COMPANY.MANAGE_TEAM,
      color: theme.colors.yellow,
    },
    {
      id: "id4",
      navTitle: "Book a Session",
      title: "Ready to book? Meet our coaches.",
      to: ROUTE_PATHS.SESSIONS.BOOK_COACHING.BROWSE,
      cta: true,
      color: theme.colors.lightGrey,
    },
  ];

  const TEAM_MEMBER_SUB_NAVIGATION_ITEMS = [
    {
      id: "id8",
      navTitle: "More Happi Explained",
      title: "Have questions about More Happi coaching? Find the answers here.",
      to: ROUTE_PATHS.LANDING,
      color: theme.colors.orange,
    },
    {
      id: "id5",
      navTitle: "More Happi 101",
      title: 'Watch our "How to use Coaching" playlist (10 mins)',
      to: ROUTE_PATHS.ONBOARDING_VIDEOS,
      color: theme.colors.blue,
    },
    {
      id: "id6",
      navTitle: "Book a Session",
      title: "Ready to book? Meet our coaches.",
      to: ROUTE_PATHS.SESSIONS.BOOK_COACHING.BROWSE,
      cta: true,
      color: theme.colors.lightGrey,
    },
  ];

  const NAV_ITEMS = user.currentUser?.isAdmin
    ? SPONSOR_SUB_NAVIGATION_ITEMS
    : TEAM_MEMBER_SUB_NAVIGATION_ITEMS;

  return (
    <div className="max-w-[1240px] my-2 mx-auto px-4">
      <PageHeader title={`Hey ${user.currentUser?.firstName},`} ghost className="site-page-header">
        <PageHeaderBlock
          image={{
            src: "/images/Landing_Page.svg",
            alt: "Home",
          }}
        >
          {mainCopy}
        </PageHeaderBlock>
      </PageHeader>
      <a href="/sessions" className="hover:no-underline">
        <div className="bg-white mt-4 rounded-xl p-4 cursor-pointer border border-solid border-lightPurple">
          <div className="text-4xl w-full text-center">View my previous and upcoming sessions</div>
        </div>
      </a>

      <Row className="mh-row-top-md" gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}>
        {NAV_ITEMS.map((item) => (
          <Col key={item.id} className="mh-vertical-spacer" xs={24} sm={24} md={12} lg={12} xl={6}>
            <ActionTile {...item} />
          </Col>
        ))}
      </Row>

      {/* {goalPlannerModalOpen && (
        <HalfModal
          size="lg"
          onClose={() => {
            setGoalPlannerModalOpen(false);

            Mixpanel.track("Goal Planner popup closed", {
              user: user?.currentUser?.id,
            });

            const seenNoSessionModal = localStorage.getItem("seenNoSessionModal");
            if (seenNoSessionModal === "2") {
              localStorage.setItem("seenNoSessionModal", "3");
            } else if (seenNoSessionModal === "1") {
              localStorage.setItem("seenNoSessionModal", "2");
            } else {
              localStorage.setItem("seenNoSessionModal", "1");
            }
          }}
        >
          <div className="flex md:h-80">
            <div className="bg-white w-1/2 rounded-l-xl">
              <div className="flex w-3/4 h-full m-auto bg-white rounded-l-xl">
                <img src="/images/Questions.png" alt="Goal Planner" className="w-full m-auto" />
              </div>
            </div>
            <div className="flex flex-col w-1/2 h-full justify-center p-4 pt-12 m-auto bg-darkGreen text-white rounded-r-xl">
              <h3 className="text-2xl font-semibold mb-6 !text-white">
                Unsure on what to bring to your first session?
              </h3>
              <p className="text-lg text-white">
                Use our quick and easy tool to assess where you&apos;re at and identify the areas
                where you&apos;d like to make improvements.
              </p>
              <div className="flex mt-auto self-end">
                <a
                  href="https://midnight-revolve-64c.notion.site/More-Happi-Goal-Planner-80d2046f54b141b5b02d5794ad08685c"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    primary
                    large
                    onClick={() => {
                      Mixpanel.track("Goal Planner viewed", {
                        user: user?.currentUser?.id,
                      });
                    }}
                  >
                    Uncover your Priorities
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </HalfModal>
      )} */}
    </div>
  );
};
