import { createContext, useContext, useEffect, useState } from "react";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "$/state/auth";
import {
  Exact,
  SessionState,
  UpdateUserToIndividualMutation,
  UserRole,
  UserUpdateInput,
  useUpdateUserMutation,
  useUpdateUserToIndividualMutation,
  useWhoAmIQuery,
  WhoAmIQuery,
} from "$/graphql/types.generated";
import { Mixpanel } from "$/tracking";
import dayjs from "dayjs";
import { orderBy } from "lodash";
import { isFuture, parseISO } from "date-fns";

export type CurrentUser = WhoAmIQuery["getUser"] & {
  fullName: string;
  isAdmin: boolean;
  isSponsor: boolean;
  isCoach: boolean;
  isManager: boolean;
  isTeamMember: boolean;
  activePackage?: WhoAmIQuery["getUser"]["packages"][0] & {
    validSessions: WhoAmIQuery["getUser"]["packages"][0]["sessions"];
    hitSessionLimit: boolean;
  };
};

export interface IUserContext {
  isLoading: boolean;
  isError: boolean;
  currentUser?: CurrentUser | null;
  update: (data: UserUpdateInput) => Promise<void>;
  updateUserToIndividual?: UseMutationResult<
    UpdateUserToIndividualMutation,
    unknown,
    Exact<{
      userId: string;
    }>,
    unknown
  >;
  invalidate: () => Promise<void>;
}

const UserContext = createContext<IUserContext>({
  isLoading: false,
  isError: false,
  currentUser: undefined,
  update: undefined,
  updateUserToIndividual: undefined,
  invalidate: undefined,
});

export const getProgrammes = (packages: WhoAmIQuery["getUser"]["packages"]) => {
  return packages.map((pack) => {
    const expired = pack?.endDate && dayjs(pack?.endDate).isBefore(dayjs());
    const validSessions = pack?.sessions?.filter((session) => {
      const coachCancelled = session.state === SessionState.CancelledCoach;
      const userCancelled = session.state === SessionState.Cancelled;

      return !(coachCancelled || userCancelled);
    });
    const hitSessionLimit = pack?.sessionCount ? validSessions?.length >= pack.sessionCount : false;

    const hasfutureSessions = !!validSessions.find((session) =>
      isFuture(parseISO(session.startsAt)),
    );

    return {
      ...pack,
      expired,
      hasFutureSessions: hasfutureSessions,
      validSessions: orderBy(validSessions, ["startsAt"], ["asc"]),
      hitSessionLimit,
    };
  });
};

export const getActivePackage = (user: WhoAmIQuery["getUser"]) => {
  if (!user?.packages) return null;

  const allProgrammes = getProgrammes(user.packages);
  const activeProgrammes = allProgrammes.filter((programme) => {
    if (programme.expired || (programme.hitSessionLimit && !programme.hasFutureSessions)) {
      return false;
    }
    return true;
  });

  const activePackage = orderBy(activeProgrammes, ["createdAt"], ["desc"])[0];
  return activePackage;
};

const withSelectors = (user: WhoAmIQuery["getUser"]) => {
  return {
    ...user,
    fullName: `${user?.firstName} ${user?.lastName}`,
    isAdmin:
      user?.role === UserRole.Sponsor ||
      user?.role === UserRole.Admin ||
      user?.role === UserRole.Superadmin,
    isSponsor: user?.role === UserRole.Sponsor || user?.role === UserRole.Individual,
    isCoach: !!user?.coach?.id,
    isManager: user?.teamMember.isManager,
    isTeamMember: user?.role === UserRole.TeamMember,
    activePackage: getActivePackage(user),
  };
};

export const UserProvider = ({ children }: any) => {
  const queryClient = useQueryClient();
  const auth = useAuth();
  const [currentUser, setCurrentUser] = useState<CurrentUser>();

  const where = {
    cognitoId: auth.state.cognitoId || "",
  };

  const { isLoading, isPending, isError, data, isSuccess } = useWhoAmIQuery(where, {
    enabled: auth.state.isAuthenticated,
  });

  useEffect(() => {
    if (isSuccess) {
      const onSuccess = async () => {
        const { getUser } = data;
        if (!data.getUser) {
          console.error("User not found in database");
          await auth.logout();
          return null;
        }

        setCurrentUser(withSelectors(getUser));

        Mixpanel.identify(getUser.id);

        return Mixpanel.people.set({
          $externalId: getUser.id,
          $name: `${getUser.firstName} ${getUser.lastName}`,
          $email: getUser.email,
          $role: getUser.role,
          $company: getUser.teamMember?.company?.name,
          $companyId: getUser.teamMember?.companyId,
        });
      };
      onSuccess();
    }
  }, [isSuccess, isPending, data]);

  const updateUser = useUpdateUserMutation({
    onSuccess: () => {
      Mixpanel.track("User Updated");

      return queryClient.invalidateQueries({ queryKey: ["WhoAmI", where] });
    },
  });

  const update = async (input: UserUpdateInput) => {
    await updateUser.mutateAsync({
      where: { id: data?.getUser?.id },
      data: input,
    });
  };

  const invalidate = () => queryClient.invalidateQueries({ queryKey: ["WhoAmI", where] });

  const updateUserToIndividual = useUpdateUserToIndividualMutation({
    onSuccess: () => {
      Mixpanel.track("User Updated to individual");

      return queryClient.invalidateQueries({ queryKey: ["WhoAmI", where] });
    },
  });

  return (
    <UserContext.Provider
      value={{
        isLoading,
        isError,
        currentUser,
        update,
        updateUserToIndividual,
        invalidate,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
